<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personDeviceLog">测温记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <div>
        <el-form-item label="记录时间" prop="timeRanges">
          <el-date-picker
            v-model="queryModel.timeRanges"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="timeRangesDefaultTime"
            size="mini"
          ></el-date-picker>
          <!-- <el-date-picker
            v-model="queryModel.timeRanges"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['12:00:00', '08:00:00']"
          ></el-date-picker>-->
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceNo">
          <el-input type="text" size="mini" v-model="queryModel.deviceNo" style="width:170px;"></el-input>
        </el-form-item>
        <el-form-item label="设备别称" prop="aliasName">
          <el-input type="text" size="mini" v-model="queryModel.aliasName" style="width:150px;"></el-input>
        </el-form-item>
        <el-form-item label="数据来源" prop="source">
          <el-select size="mini" v-model="queryModel.source" style="width:100px;">
            <el-option label="所有" value=""></el-option>
            <el-option label="设备测温" value="1"></el-option>
            <el-option label="体温上报" value="2"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="单位" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="200"
          ></el-select-tree>&nbsp;
          <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
          <el-checkbox v-model="queryModel.distinct">是否去重</el-checkbox>
        </el-form-item>
        <el-form-item label="人员编号" prop="personId">
          <el-input type="text" size="mini" v-model="queryModel.personId" style="width:100px;"></el-input>
        </el-form-item>
        <el-form-item label="人员姓名" prop="personName">
          <el-input type="text" size="mini" v-model="queryModel.personName" style="width:100px;"></el-input>
        </el-form-item>
        <el-form-item label="温度区间" prop="temperatureBegin">
          <el-input
            type="text"
            size="mini"
            style="width:60px;"
            v-model="queryModel.temperatureBegin"
          ></el-input>-
          <el-input type="text" size="mini" style="width:60px;" v-model="queryModel.temperatureEnd"></el-input>
        </el-form-item>
        <el-form-item label="匹配状态" prop="matchMsg">
          <el-select size="mini" v-model="queryModel.matchMsg" style="width:100px;">
            <el-option label="未选择" value=""></el-option>
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员状态" prop="personDelFlag">
          <el-select size="mini" v-model="queryModel.personDelFlag" style="width:100px;">
            <el-option label="所有" value=""></el-option>
            <el-option label="正常" value="0"></el-option>
            <el-option label="已删除" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份" prop="popedom">
            <el-select
                v-model="queryModel.popedom"
                filterable
                clearable
                placeholder="请选择"
                style="width:100px;"
                size="mini"
              >
                <el-option
                  v-for="item in popedomList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
          </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >查询</el-button>&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
          >重置</el-button>&nbsp;
          <el-button
            type="primary"
            size="mini"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
          >导出数据</el-button>
          &nbsp;
          <el-button
            type="primary"
            size="mini"
            plain
            icon="el-icon-remove"
            :disabled="multipleSelection.length==0"
            @click="handleBatchDelete"
          >删除异常数据</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-divider></el-divider>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :element-loading-text="loadingText"
      stripe
      :height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="recordTime" label="记录时间" width="180"></el-table-column>
      <el-table-column prop="faceImage" label="头像" width="80">
        <template slot-scope="{row}">
          <!-- <a :href="row.faceImage+'?x-oss-process=image/resize,m_lfit,w_1024'" target="_blank">
            <el-avatar
              :size="48"
              :src="row.faceImage+'?x-oss-process=image/resize,m_fill,w_48'"
              :key="row.id"
            ></el-avatar>
          </a> -->
          <a :href="row.faceImage" target="_blank">
            <img
              :src="row.faceImage+'?x-oss-process=image/resize,mfit,w_120'"
              :key="row.id"
              style="object-fit:cover;width:60px;height:60px;border-radius:10px;"
            />
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="personId" label="人员编号" width="100"></el-table-column>
      <el-table-column prop="person" label="人员姓名" width="100">
        <template slot-scope="{row}">
          <span v-if="row.personName == '' || row.personName == null">
            {{row.person == null ?"匿名用户" : row.person.name}}
          </span>
          <span v-else>
            {{row.personName == "未识别" ? "匿名用户" : row.personName}}
          </span>
          </template>
      </el-table-column>
      <el-table-column prop="schoolName" label="所属学校" width="150">
        <template slot-scope="{row}">
          {{row.schoolName != null ? row.schoolName : row.companyName}}
        </template>
      </el-table-column>
      <el-table-column prop="device" label="设备别称" width="150">
        <template slot-scope="{row}">{{row.device == null ? "" : row.device.aliasName}}</template>
      </el-table-column>      
      <el-table-column prop="person" label="人员状态" width="80">
        <template slot-scope="{row}">
          <div v-if="row.person!=null">
            <span v-if="row.person.delFlag">
              已删除 
              <el-button size="mini" type="warning" @click="handleFix(row)">修正</el-button>
            </span>
            <span v-else>
              正常
            </span>
          </div>
          <div v-else>
            未识别
          </div>
          </template>
      </el-table-column>
      <el-table-column prop="temperature" label="测温度数" width="80">
        <template slot-scope="{ row }">
          <span v-if="row.temperature"
            >{{ row.temperature }}
            <el-link
              type="primary"
              :underline="false"
              @click="handleEditTemperature(row)"
              v-show="batchEditVisible"
              >编辑</el-link
            ></span
          ></template>
      </el-table-column>
      <!-- <el-table-column prop="matchStatus" label="匹配状态" width="180"></el-table-column> -->
      <el-table-column prop="matchMsg" label="匹配状态" width="150"></el-table-column>
      <!-- <el-table-column prop="matchFaceId" label="匹配的人脸id" width="180"></el-table-column> -->
      <!-- <el-table-column prop="position1" label="一级位置" width="150"></el-table-column>
      <el-table-column prop="position2" label="二级位置" width="150"></el-table-column>
      <el-table-column prop="position3" label="三级位置" width="150"></el-table-column>
      <el-table-column prop="position4" label="四级位置" width="150"></el-table-column>
      <el-table-column prop="position5" label="五级位置" width="150"></el-table-column>-->
      <el-table-column prop="deviceNo" label="设备编号" width="220"></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <el-dialog
      :visible.sync="showModal"
      title="修改健康码"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align: left"
      :close-on-click-modal="false"
      :loading="downloadLoading"
    >
      <el-form ref="form" :model="formModel" :label-width="'130px'">
        <el-form-item label="打卡温度" prop="temperature">
          <el-input v-model="formModel.temperature"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">关闭</el-button>
        <el-button type="primary" @click="handleUpdateTemperature"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import personDeviceLogApi from "@/api/base/personDeviceLog";
import companyInfoApi from "@/api/base/companyInfo";
import permissionApi from "@/api/sys/permission";
import SelectTree from "@/components/SelectTree";
import pageUtil from "@/utils/page";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  name: 'BasePersonDeviceLogList',
  data() {
    var self = this;

    return {
      queryModel: {
        deviceNo: "",
        aliasName: "",
        personId: "",
        personName: "",
        matchStatus: "",
        timeRanges: "",
        faceImage: "",
        position: "",
        temperatureBegin: "",
        temperatureEnd: "",
        temperatureRanges: "",
        matchMsg: "",
        companyId: "",
        subordinate: false,
        distinct: false,
        source: "",
        personDelFlag: "",
        popedom: ""
      },
      loadingText:"加载中",
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      downloadLoading: false,
      tableHeight: 400,
      timeRangesDefaultTime: [],
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      popedomList: [],
      formModel: { id: "", temperature: "" },
      batchEditVisible: false,
    };
  },
  created() {
    var self = this;
    this.getCurrentMonthFirst();

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName": "用户身份"
    }).then(response => {
      var jsonData = response.data;
      this.popedomList = jsonData.data;
    });

    permissionApi
      .hasPermission("post", "/personDeviceLog/update")
      .then((response) => {
        this.batchEditVisible = response.data.result;
      });

    this.loadTree();
  },
  methods: {
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;
      self.loadingText = "加载中";

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("faceImage", self.queryModel.faceImage);

      if (self.queryModel.timeRanges == null) {
        self.queryModel.timeRanges = "";
      }

      formData.append("timeRanges", self.queryModel.timeRanges);
      formData.append("matchStatus", self.queryModel.matchStatus);
      formData.append("personId", self.queryModel.personId);
      formData.append("personName", self.queryModel.personName);
      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("aliasName", self.queryModel.aliasName);
      formData.append("position", self.queryModel.position);
      formData.append("matchMsg", self.queryModel.matchMsg);
      formData.append("personDelFlag", self.queryModel.personDelFlag);

      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }

      formData.append("companyId", self.queryModel.companyId);
      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("distinct", self.queryModel.distinct);

      formData.append("popedom", self.queryModel.popedom);

      formData.append(
        "temperatureRanges",
        self.queryModel.temperatureBegin + "," + self.queryModel.temperatureEnd
      );

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personDeviceLogApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.queryModel.timeRanges = "";
      this.queryModel.temperatureEnd = "";
      this.queryModel.subordinate = false;
      this.queryModel.distinct = false;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleFix(record) {
      var self = this;

      self
        .$confirm("是否将该删除用户替换为相同班级中姓名相同的正常用户?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          personDeviceLogApi.fixPerson(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: jsonData.message + ""
              });
            }
            else{
              self.$message.error(jsonData.message + "");
            }
          });
        });
    },
    downloadXls() {
      //导出
      var self = this;
      self.downloadLoading = true;
      self.loading = true;
      self.loadingText = "数据导出中，请稍后...";

      var formData = new FormData();

      formData.append("faceImage", self.queryModel.faceImage);
      formData.append("timeRanges", self.queryModel.timeRanges);
      formData.append("matchStatus", self.queryModel.matchStatus);
      formData.append("personName", self.queryModel.personName);
      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("aliasName", self.queryModel.aliasName);

      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }

      formData.append("companyId", self.queryModel.companyId);
      formData.append("position", self.queryModel.position);

      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("distinct", self.queryModel.distinct);

      formData.append("matchMsg", self.queryModel.matchMsg);
      formData.append("source", self.queryModel.source);

      
      formData.append("popedom", self.queryModel.popedom);

      var timestamp = (new Date()).getTime();
      formData.append("timestamp", timestamp);

      formData.append("pageSize", 10000);

      personDeviceLogApi.exportXls(formData).then(function(response) {
        var jsonData = response.data;
        self.downloadLoading = false;
        self.loading = false;
        if (jsonData.result) {
            //下载有错误信息提示的报表
            self.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: `<a href="${jsonData.data}" target="_blank">${jsonData.message}</a>&nbsp;`,
              duration: 60000,
              type: 'success'
            });
        }
        else{
          self.$message.error(jsonData.message);
        }
      });

      //定时查询导出进度
      this.queryExportProgress(timestamp);
    },
    queryExportProgress(timestamp) {
        if(this.loading) {
          personDeviceLogApi.exportProgress(timestamp).then(resp=>{
            var jsonData = resp.data;

            if(jsonData.result){
              this.loadingText = jsonData.message + "";
            }

            setTimeout(()=>{
              this.queryExportProgress(timestamp)
            },1000);
          })
        }
    },
    //初始化日期
    getCurrentMonthFirst() {
      var self = this;
      var date = new Date();
      var startDate = new Date();
      var month = parseInt(date.getMonth() + 1);

      //当前测温记录

      var startMonth = month;
      // if (startDate.getDate() < 7) {
      //   startMonth = startDate.getMonth();
      //   startDate.setDate(0);
      // }

      // startDate = startDate.getDate() - 7;

      var startTime =
        date.getFullYear() + "-" + startMonth + "-" + startDate.getDate() + " 00:00:00";

      var endTime =
        date.getFullYear() + "-" + month + "-" + date.getDate() + " 23:59:59";

      self.queryModel.timeRanges = [startTime, endTime];
    },
    handleBatchDelete() {
      var self = this;
      var maxTemperature = 37.3;

      var hasNormal = this.multipleSelection.filter(record=>{
        return record.temperature <= maxTemperature 
      });

      console.log(hasNormal.length);
      
      if(hasNormal.length>0){
         self.$message.error("只能选择温度大于" + maxTemperature + "的记录!");
         return;
      }

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        personDeviceLogApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleEditTemperature(record) {
      var self = this;
      self.formModel.id = record.id;
      self.formModel.temperature = record.temperature;
      self.showModal = true;
    },
    
    handleUpdateTemperature(record) {
      var self = this;
      self.loading = true;

      personDeviceLogApi.update(this.formModel).then(function (response) {
        self.loading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          self.showModal = false;
          self.changePage(self.pageIndex);
          self.formModel.id = "";
          self.formModel.temperature = "";
          self.$message({
            type: "success",
            message: "修改成功!",
          });
        } else {
          self.$message.success("修改失败!");
        }
      });
    },
  },
  mounted: function() {
    var self = this;
    this.changePage(1);

    setTimeout(()=>{
      //45为分页栏的高度
      //页面高度-列表上面的高度-分页栏高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    },1000);
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>